import * as React from 'react'
import Layout from '../components/Layout/index'

const Impressum = () => (
    <Layout>
        <div className="p-4">
            <h2>Impressum</h2>
            <p>
                Lena Johanna Kramer
                <br />
                Mimo Matosi
                <br />
                Regentenstr. 13
                <br />
                41061 Mönchengladbach
            </p>
            <p>
                Telefon: 015753713635
                <br />
                E-Mail:{' '}
                <a href="mailto:kontakt@mimomatosi.de">kontakt@mimomatosi.de</a>
                <br />
            </p>
        </div>
    </Layout>
)

export default Impressum
